(() => {
	const frontpageProductsPurpose = document.querySelector('#frontpage-products-purpose');

	if ( typeof frontpageProductsPurpose != 'undefined' && frontpageProductsPurpose != null ) {
		new Swiper(frontpageProductsPurpose, {
			slidesPerView: 1,
			spaceBetween: 40,
			speed: 1200,
			pagination: {
				el: '#frontpage-products-purpose__pagination',
				clickable: true
			},
			breakpoints: {
			    640: {
			    	slidesPerView: 2,
			    },
			    1024: {
			    	slidesPerView: 3
			    } 
			},
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			}
		});
	}
})();

(() => {
	new Swiper('#frontpage-header-slider', {
		slidesPerView: 1,
	});
})();

(() => {
	new Swiper('#footer-customers-slider', {
		slidesPerView: 2,
		spaceBetween: 40,
		speed: 1200,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		breakpoints: {
			640: {
		      slidesPerView: 3,
		      spaceBetween: 40
		    },
			980: {
		      slidesPerView: 4,
		      spaceBetween: 80
		    },
			1200: {
		      slidesPerView: 5,
		      spaceBetween: 120
		    },
		}
	});
})();

(() => {
	const frontpageEcoSlider = document.querySelector('#frontpage-eco-slider');

	if ( typeof frontpageEcoSlider != 'undefined' && frontpageEcoSlider != null ) {
		new Swiper(frontpageEcoSlider, {
			slidesPerView: 1,
			spaceBetween: 40,
			speed: 1200,
			autoplay: {
				delay: 8000,
				disableOnInteraction: false,
			},
			breakpoints: {
				640: {
			      slidesPerView: 2,
			      spaceBetween: 40
			    },
				980: {
			      slidesPerView: 3,
			      spaceBetween: 40
			    },
			}
		});
	}
})();